'use strict'

routes.my_order = function(id, action) {
     mount('loading');
     
     if(!isAnonymous){
         orderService.fetchMyOrders()
        .then(function(orders) {
          
          
          var myOrders={
            
          }
          
             for (var key in orders ){
               myOrders[key]=orders[key];
             }
             console.log(myOrders);
          
            mount('my_order', { my_order_items: myOrders});
         });
         
         console.log("notanon");
     }
     else{
          console.log("anon");
         mount('my_order', { my_order_items: {}});
     }
  
}
